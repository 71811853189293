import { Link } from "@remix-run/react";
import { RemixLinkProps } from "@remix-run/react/dist/components";
import {
	ComponentProps,
	ForwardRefRenderFunction,
	PropsWithChildren,
	forwardRef,
} from "react";

export interface CustomButtonProps {
	border?: boolean;
	cta?: boolean;
}

function isRemixLinkProps(props: any): props is RemixLinkProps {
	return props && props.to;
}

type ButtonRef = HTMLAnchorElement | HTMLButtonElement;

type ButtonProps = PropsWithChildren<
	ComponentProps<"button"> | RemixLinkProps
> &
	CustomButtonProps;

export const ButtonRenderer: ForwardRefRenderFunction<
	ButtonRef,
	ButtonProps
> = ({ children, ...allProps }, ref) => {
	const { cta, border, ...props } = allProps;

	if (isRemixLinkProps(props)) {
		return (
			<Link
				{...props}
				className={`flex items-center justify-center w-full px-6 py-4 gap-4 disabled:opacity-40 
					${border ? "border-2 border-black/80" : ""}
					${cta ? "bg-accent rounded-xl" : "rounded-lg"}
					${props.className || ""}`}
				ref={ref}
			>
				{children}
			</Link>
		);
	}
	return (
		<button
			type="button"
			{...props}
			className={`flex items-center justify-center text-center w-full px-6 py-4 gap-4 disabled:opacity-40 
				${border ? "border-2 border-black/80" : ""}
				${cta ? "bg-accent rounded-xl" : "rounded-lg"}
				${props.className || ""}`}
			ref={ref}
		>
			{children}
		</button>
	);
};

export const Button = forwardRef<ButtonRef, ButtonProps>(ButtonRenderer);
